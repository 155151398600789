import React, { useState } from 'react';
import {
 Container
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Inputdata from "../Data/InputData.json";


const LandingLayout = (props) => {
 const [darkMode, setDarkMode] = useState(true);
 return (
  <div
   className={darkMode ? "app-light light-mode page-height" : "app-light dark-mode page-height"}
  >
   <Container>
    <div className="page-header ">
     <Link to={"/"} >
      <img src={Inputdata.img_logo} />
     </Link>
     <form>
      <label className="switch">
       <input type="checkbox" onClick={() => setDarkMode(!darkMode)} />
       <span className="switch-slider"></span>
      </label>
     </form>
    </div>
    {React.cloneElement(props.children)}
   </Container>
  </div>
 );
}

export default LandingLayout;
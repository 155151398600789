import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changePasswordStart } from "../store/actions/AdminAction";

const ChangePassword = (props) => {
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(changePasswordStart(inputData));
  };
  return (
    <>
      <main class="h-full pb-16 overflow-y-auto content-wrapper">
        <div class="container px-6 mx-auto grid content-section">
          <div class="apps-card">
            <div class="px-4 py-3 mb-8 app-card mt-4 w-100">
              <h2 class="my-6 text-2xl font-semibold ">Change password</h2>

              <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label class="block">
                    <span>Old Password</span>
                    <input
                      class="block   form-input"
                      placeholder="Enter Password"
                      type="password"
                      name="old_password"
                      value={inputData.old_password}
                      onChange={(event) =>
                        setInputData({
                          ...inputData,
                          old_password: event.currentTarget.value,
                        })
                      }
                    />
                  </label>
                </div>
                <div className="custom-input">
                  <label class="block">
                    <span>New Password</span>
                    <input
                      class="block form-input"
                      placeholder="Enter New Password"
                      type="password"
                      name="password"
                      value={inputData.password}
                      onChange={(event) =>
                        setInputData({
                          ...inputData,
                          password: event.currentTarget.value,
                        })
                      }
                    />
                  </label>
                </div>
                <div className="custom-input">
                  <label class="block">
                    <span>Confirm Password</span>
                    <input
                      class="block  form-input"
                      placeholder="Enter Confirm Password"
                      type="password"
                      name="password_confirmation"
                      value={inputData.password_confirmation}
                      onChange={(event) =>
                        setInputData({
                          ...inputData,
                          password_confirmation: event.currentTarget.value,
                        })
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="app-card-buttons sub-btn">
                <button
                  className="content-button status-button"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

// export default Profile;

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(ChangePassword);

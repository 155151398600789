import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchSettingsStart,
  settingsUpdateStart,
} from "../store/actions/AdminAction";

const Settings = (props) => {
  const [inputData, setInputData] = useState({
    infura_key: "",
    tag_name: "",
    meta_description: "",
    site_name: "",
    site_logo: "",
    site_icon: "",
    contract_address: "",
  });

  const [image, setImage] = useState({
    favicon: {
      file: "",
      preview_image: "",
    },
    logo: {
      file: "",
      preview_image: "",
    },
  });

  useEffect(() => {
    props.dispatch(fetchSettingsStart());
  }, []);

  const handleFaviconChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        favicon: {
          file: file,
          preview_image: reader.result,
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleLogoChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        logo: {
          file: file,
          preview_image: reader.result,
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newData = {
      ...inputData,
      site_icon:
        image.favicon.file != "" ? image.favicon.file : inputData.site_icon,
      site_logo: image.logo.file != "" ? image.logo.file : inputData.site_logo,
    };
    if (image.favicon.file == "") delete newData.site_icon;

    if (image.logo.file == "") delete newData.site_logo;
    props.dispatch(settingsUpdateStart(newData));
  };

  useEffect(() => {
    if (!props.settings.loading) {
      setInputData({
        ...inputData,
        infura_key: props.settings.data.infura_key,
        site_name: props.settings.data.site_name,
        tag_name: props.settings.data.tag_name,
        site_logo: props.settings.data.site_logo,
        site_icon: props.settings.data.site_icon,
        meta_description: props.settings.data.meta_description,
        contract_address: props.settings.data.contract_address,
      });
    }
  }, [props.settings.data]);

  const handleFaviconRemove = () => {
    setImage({
      ...image,
      favicon: {
        file: "",
        preview_image: "",
      },
    });
  };

  const handleLogoRemove = () => {
    setImage({
      ...image,
      logo: {
        file: "",
        preview_image: "",
      },
    });
  };

  return (
    <>
      <main class="h-full pb-16 overflow-y-auto content-wrapper">
        <div class="container px-6 mx-auto grid content-section">
          <div className="apps-card">
            <div class="px-4 py-3 mb-8 app-card mt-4 w-100">
              <h2 class="my-6 text-2xl font-semibold ">Settings</h2>
              <Form onSubmit={(event) => handleSubmit(event)}>
                {props.settings.loading ? (
                  "Loading"
                ) : (
                  <>
                    <Row>
                      <Col lg={6}>
                        <div className="custom-input">
                          <label class="block">
                            <span>Infura</span>
                            <input
                              class="block form-input"
                              placeholder="Enter Infura key"
                              name="infura_key"
                              value={inputData.infura_key}
                              onChange={(event) => handleInputChange(event)}
                            />
                          </label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="custom-input">
                          <label class="block">
                            <span>Contract Address</span>
                            <input
                              class="block form-input"
                              placeholder="Enter Contract Address"
                              name="contract_address"
                              value={inputData.contract_address}
                              onChange={(event) => handleInputChange(event)}
                            />
                          </label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="custom-input">
                          <label class="block">
                            <span>Site Name</span>
                            <input
                              class="block form-input"
                              placeholder="Enter website title"
                              name="site_name"
                              value={inputData.site_name}
                              onChange={(event) => handleInputChange(event)}
                            />
                          </label>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="custom-input">
                          <label class="block">
                            <span>Tag Name</span>
                            <input
                              class="block form-input"
                              placeholder="Enter Tag Name"
                              name="tag_name"
                              value={inputData.tag_name}
                              onChange={(event) => handleInputChange(event)}
                            />
                          </label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={5}>
                            <div className="block  mb-2">
                              <label class="block  mb-1">
                                <span>Favicon</span>
                              </label>
                              <div className="custom-file-btn-wrapper ">
                                <input
                                  id="fileSelect"
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) =>
                                    handleFaviconChange(event)
                                  }
                                />
                                <div className="app-card-buttons">
                                  <button className="content-button status-button">
                                    Choose Favicon
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={7}>
                            {image.favicon.preview_image != "" ? (
                              <div className="preview-image-wrapper">
                                <img
                                  src={image.favicon.preview_image}
                                  alt=""
                                  className="settings-previewimage "
                                />
                                <div className="file-remove-btn">
                                  <button
                                    className=" flex items-center justify-between px-2 py-2 font-medium leading-5"
                                    aria-label="Delete"
                                    onClick={handleFaviconRemove}
                                  >
                                    <svg
                                      fill="#fff"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      width="24"
                                    >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              inputData.site_icon != "" && (
                                <div>
                                  <img
                                    src={inputData.site_icon}
                                    alt=""
                                    className="settings-previewimage "
                                  />
                                </div>
                              )
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={5}>
                            <div className="block  mb-2">
                              <label class="block  mb-1">
                                <span>Logo</span>
                              </label>
                              <div className="custom-file-btn-wrapper ">
                                <input
                                  id="fileSelect"
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) => handleLogoChange(event)}
                                />
                                <div className="app-card-buttons">
                                  <button className="content-button status-button ">
                                    Choose Logo
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={7}>
                            {image.logo.preview_image != "" ? (
                              <div className="preview-image-wrapper">
                                <img
                                  src={image.logo.preview_image}
                                  alt=""
                                  className="settings-previewimage "
                                />
                                <div className="file-remove-btn">
                                  <button
                                    className=" flex items-center justify-between px-2 py-2  font-medium leading-5 "
                                    aria-label="Delete"
                                    onClick={handleLogoRemove}
                                  >
                                    <svg
                                      fill="#fff"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      width="24"
                                    >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              inputData.site_logo != "" && (
                                <div>
                                  <img
                                    src={inputData.site_logo}
                                    alt=""
                                    className="settings-previewimage "
                                  />
                                </div>
                              )
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12}>
                        <div className="custom-input">
                          <label class="block  col-span-2">
                            <span>Meta Description</span>
                            <textarea
                              class="block"
                              rows="3"
                              placeholder="Enter Meta description"
                              name="meta_description"
                              value={inputData.meta_description}
                              onChange={(event) => handleInputChange(event)}
                            ></textarea>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="w-100 text-center app-card-buttons sub-btn">
                      <button
                        type="submit"
                        className="content-button status-button"
                      >
                        submit
                      </button>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

const mapStateToPros = (state) => ({
  settings: state.admins.settings,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Settings);

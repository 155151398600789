import {
  ADMIN_LOGIN_START,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  FETCH_ADMIN_DETAILS_START,
  FETCH_ADMIN_DETAILS_SUCCESS,
  FETCH_ADMIN_DETAILS_FAILURE,
  EDIT_ADMIN_DETAILS,
  UPDATE_ADMIN_DETAILS_START,
  UPDATE_ADMIN_DETAILS_SUCCESS,
  UPDATE_ADMIN_DETAILS_FAILURE,
  ADMIN_CHANGE_PASSWORD_START,
  ADMIN_CHANGE_PASSWORD_SUCCESS,
  ADMIN_CHANGE_PASSWORD_FAILURE,
  SETTINGS_UPDATE_START,
  SETTINGS_UPDATE_SUCCESS,
  SETTINGS_UPDATE_FAILURE,
  FETCH_SETTINGS_START,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  login: {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  profile: {
    data: {},
    loading: true,
    error: false,
  },
  profileInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  buttonDisable: false,
  loadingButtonContent: null,
  loginInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  changePassword: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  settingsUpdate: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  settings: {
    data: {},
    loading: true,
    error: false,
  },
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_DETAILS_START:
      return {
        ...state,
        profile: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case EDIT_ADMIN_DETAILS:
      return {
        ...state,
        profileInputData: {
          ...state.profileInputData,
          loading: false,
          error: false,
          data: {
            ...state.profileInputData.data,
            [action.name]: action.value,
          },
        },
      };
    case UPDATE_ADMIN_DETAILS_START:
      return {
        ...state,
        profileInputData: {
          ...state.profileInputData,
          // data: {
          //   email: state.profile.data.admin.email,
          //   name: state.profile.data.admin.name,
          //   about: state.profile.data.admin.about,
          //   mobile:
          //     state.profile.data.admin.mobile != null
          //       ? state.profile.data.admin.mobile
          //       : "",
          //   picture: action.data
          //     ? action.data.admin.picture != undefined
          //       ? action.data.admin.picture
          //       : ""
          //     : "",
          // },
          buttonDisable: true,
          loadingButtonContent: "Loading...please wait",
        },
      };
    case UPDATE_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: {admin : action.data.data},
          buttonDisable: false,
          loadingButtonContent: null,
          loading: false,
        },
      };
    case UPDATE_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: state.profile.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case ADMIN_LOGIN_START:
      return {
        ...state,
        login: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading....Please Wait",
        },
      };
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        login: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case ADMIN_CHANGE_PASSWORD_START:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading....Please Wait",
        },
      };
    case ADMIN_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADMIN_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case SETTINGS_UPDATE_START:
      return {
        ...state,
        settingsUpdate: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading....Please Wait",
        },
      };
    case SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        settingsUpdate: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SETTINGS_UPDATE_FAILURE:
      return {
        ...state,
        settingsUpdate: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_SETTINGS_START:
      return {
        ...state,
        settings: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        settings: {
          data: {},
          loading: false,
          error: action.data,
        },
      };

    default:
      return state;
  }
};

export default AdminReducer;

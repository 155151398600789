import React, { useState, useEffect } from "react";
import { Notify } from "react-redux-notify";
import { Helmet } from "react-helmet";

const ThemeLayout = (props) => {
  return (
    <>
      <Notify position="TopRight" />
      <Helmet>
        <link rel="stylesheet" 
         rel="stylesheet"
         type="text/css"
         href={window.location.origin + "/assets/css/tailwind.output.css"}
        />
        <link rel="stylesheet" 
         rel="stylesheet"
         type="text/css"
         href={window.location.origin + "/assets/css/style.css"}
        />
      <script
        src="../../cdn.jsdelivr.net/gh/alpinejs/alpine%40v2.x.x/dist/alpine.min.js"
        defer
      ></script>
      <script 
      src={window.location.origin + "/assets/js/init-alpine.js"}></script>
      </Helmet>
      {React.cloneElement(props.children)}
    </>
  );
};

export default (ThemeLayout);

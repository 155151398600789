import React, { useState, useEffect } from "react";
import { Notify } from "react-redux-notify";
import Sidebar from "./Sidebar";
import { Helmet } from "react-helmet";
import Authheader from "./Header/Authheader";

const AuthLayout = (props) => {
  const [sideBarState, setSideBarState] = useState({
    mobile: null,
    mobileSideBar: false,
  });

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setSideBarState({
        ...sideBarState,
        mobile: true,
      });
    } else {
      setSideBarState({
        ...sideBarState,
        mobile: false,
      });
    }
  };

  const handleSideBarToggle = () => {
    setSideBarState({
      ...sideBarState,
      mobileSideBar: !sideBarState.mobileSideBar,
    });
  };

  return (
    <>
      <Notify position="TopRight" />
      <Helmet>
        <link
          rel="stylesheet"
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/css/tailwind.output.css"}
        />
        <link
          rel="stylesheet"
          rel="stylesheet"
          type="text/css"
          href={window.location.origin + "/assets/css/style.css"}
        />
        <script
          src="../../cdn.jsdelivr.net/gh/alpinejs/alpine%40v2.x.x/dist/alpine.min.js"
          defer
        ></script>
        <script
          src={window.location.origin + "/assets/js/init-alpine.js"}
        ></script>
      </Helmet>
      <div class="flex h-screen bg-image">
        <Sidebar sideBarState={sideBarState} />
        <div class="flex flex-col flex-1 w-full main-container">
          <div className="main-header">
            <Authheader handleSideBarToggle={handleSideBarToggle} />
          </div>

          {React.cloneElement(props.children)}
        </div>
      </div>
    </>
  );
};

export default AuthLayout;

import {
  ADMIN_LOGIN_START,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  FETCH_ADMIN_DETAILS_START,
  FETCH_ADMIN_DETAILS_SUCCESS,
  FETCH_ADMIN_DETAILS_FAILURE,
  ADMIN_CHANGE_PASSWORD_START,
  ADMIN_CHANGE_PASSWORD_SUCCESS,
  ADMIN_CHANGE_PASSWORD_FAILURE,
  UPDATE_ADMIN_DETAILS_START,
  UPDATE_ADMIN_DETAILS_SUCCESS,
  UPDATE_ADMIN_DETAILS_FAILURE,
  EDIT_ADMIN_DETAILS,
  EDIT_ADMIN_CHANGE_PASSWORD,
  SETTINGS_UPDATE_START,
  SETTINGS_UPDATE_SUCCESS,
  SETTINGS_UPDATE_FAILURE,
  FETCH_SETTINGS_START,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE
} from "./ActionConstant";

export function adminLoginStart(data) {
  return {
    type: ADMIN_LOGIN_START,
    data,
  };
}

export function adminLoginSuccess(data) {
  return {
    type: ADMIN_LOGIN_SUCCESS,
    data,
  };
}

export function adminLoginFailure(error) {
  return {
    type: ADMIN_LOGIN_FAILURE,
    error,
  };
}

// Get Admin details actions.

export function fetchAdminDetailsStart(data) {
  return {
    type: FETCH_ADMIN_DETAILS_START,
    data,
  };
}

export function fetchAdminDetailsSuccess(data) {
  return {
    type: FETCH_ADMIN_DETAILS_SUCCESS,
    data,
  };
}

export function fetchAdminDetailsFailure(error) {
  return {
    type: FETCH_ADMIN_DETAILS_FAILURE,
    error,
  };
}

// Edit Admin details action.

export function editAdminDetails(name, value) {
  return {
    type: EDIT_ADMIN_DETAILS,
    name,
    value,
  };
}

// Update Admin detatils actions

export function updateAdminDetailsStart(data) {
  return {
    type: UPDATE_ADMIN_DETAILS_START,
    data,
  };
}

export function updateAdminDetailsSuccess(data) {
  return {
    type: UPDATE_ADMIN_DETAILS_SUCCESS,
    data,
  };
}

export function updateAdminDetailsFailure(error) {
  return {
    type: UPDATE_ADMIN_DETAILS_FAILURE,
    error,
  };
}

// change password edit option

export function editChangePassword(name, value) {
  return {
    type: EDIT_ADMIN_CHANGE_PASSWORD,
    name,
    value,
  };
}

// Change password actions.

export function changePasswordStart(data) {
  return {
    type: ADMIN_CHANGE_PASSWORD_START,
    data,
  };
}

export function changePasswordSuccess(data) {
  return {
    type: ADMIN_CHANGE_PASSWORD_SUCCESS,
    data,
  };
}

export function changePasswordFailure(error) {
  return {
    type: ADMIN_CHANGE_PASSWORD_FAILURE,
    error,
  };
}

// settings update

export function settingsUpdateStart(data) {
  return {
    type: SETTINGS_UPDATE_START,
    data,
  };
}

export function settingsUpdateSuccess(data) {
  return {
    type: SETTINGS_UPDATE_SUCCESS,
    data,
  };
}

export function settingsUpdateFailure(error) {
  return {
    type: SETTINGS_UPDATE_FAILURE,
    error,
  };
}

//fetch settings

export function fetchSettingsStart(data) {
  return {
    type: FETCH_SETTINGS_START,
    data,
  };
}

export function fetchSettingsSuccess(data) {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    data,
  };
}

export function fetchSettingsFailure(error) {
  return {
    type: FETCH_SETTINGS_FAILURE,
    error,
  };
}
import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../translation/en.json";
import pt from "../translation/pt.json";
import EmptyLayout from "../Layouts/EmptyLayout";
import ThemeLayout from "../Layouts/ThemeLayout";
import NotFoundIndex from "../Handlers/ErrorPages/NotFoundIndex";
import TestingIndex from "../Testing/TestingIndex";
import HomePageIndex from "../HomePage/HomePageIndex";
import SeeAllIndex from "../SeeAll/SeeAllIndex";
import GenerateNFTIndex from "../GenerateNft/GenerateNFTIndex";
import DashBoard from "../dashboard/DashBoard";
import AuthLayout from "../Layouts/AuthLayout";
import Profile from "../Account/Auth/Profile";
import ListOfNFT from "../ntf/ListOfNFT";
import SingleNFT from "../ntf/SingleNFT";
import NFTImageUpload from "../GenerateNft/NFTImageUpload";
import ChangePassword from "../Account/ChangePassword";
import Settings from "../Account/Settings";
import LoginIndex from "../Account/Auth/LoginIndex";
import Logout from "../Account/Auth/Logout";
import EditNFT from "../ntf/EditNFT";
import AddNFTImage from "../ntf/AddNFTImage";
import DeployContractIndex from "../BlockchainConfig/DeployContractIndex";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import SinglePageIndex from "../SinglePage/SinglePageIndex";
import LandingLayout from "../Layouts/LandingLayout";
import ConfigIndex from "../BlockchainConfig/ConfigIndex";

const history = createHistory();
const $ = window.$;

setTranslations({ pt, en });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);

  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      console.log("response", response);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      console.log("china", configuration.get("configData.contract_address"));
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }
  }


  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }
    return (
      <>
        <Switch>
          <AppRoute
            path={"/"}
            component={HomePageIndex}
            exact
            layout={LandingLayout}
          />
          <AppRoute path={"/assets/:contract_address/:token_id"} component={SinglePageIndex} layout={LandingLayout} />
          <AppRoute
            path={"/test"}
            component={TestingIndex}
            layout={EmptyLayout}
          />
          {/* <AppRoute
            path={"/home"}
            component={HomePageIndex}
            layout={EmptyLayout}
          /> */}
          <AppRoute
            path={"/admin-login"}
            component={LoginIndex}
            layout={ThemeLayout}

          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={Profile}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/config"}
            component={ConfigIndex}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/settings"}
            component={Settings}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePassword}
            layout={AuthLayout}
          />
          <AppRoute
            path={"/see-all"}
            component={SeeAllIndex}
            layout={EmptyLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/generate-your-nft"}
            component={GenerateNFTIndex}
            layout={EmptyLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/generate-nft-image"}
            component={NFTImageUpload}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/deploy-contract"}
            component={DeployContractIndex}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/dashboard"}
            component={DashBoard}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/list_of_nft"}
            component={ListOfNFT}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/single-nft/:u_id/:id"}
            component={SingleNFT}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-nft/:u_id/:id"}
            component={EditNFT}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-nft-image"}
            component={AddNFTImage}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={AuthLayout}
          />
          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;

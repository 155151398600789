import React from "react";

import { Select, makeStyles, FormControl, InputLabel } from "@material-ui/core";

const useStyles = makeStyles({
  select: {
    border: "1px solid #fff",
    minWidth: "120px",
    borderRadius: "5px",
    padding: "0.5em",

    "& .MuiInput-root": {
      color: "red",
    },

    "&:before": {
      content: "",
      display: "none",
    },
    "&:after": {
      borderColor: "white",
      content: "",
      display: "none",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  selectLabel: {
    // top : "0.5em",
    left: "0.5em",
    color: "#fff",

    "& svg": {
      fill: "#fff",
    },
  },
});

const CustomSelect = (props) => {
  const classes = useStyles();

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel
          id="demo-controlled-open-select-label"
          className={classes.selectLabel}
        >
          {props.label}
        </InputLabel>
        <Select
          className={classes.select}
          {...props}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        ></Select>
      </FormControl>
    </>
  );
};

export default CustomSelect;

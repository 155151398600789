import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  editAdminDetails,
  fetchAdminDetailsStart,
  updateAdminDetailsStart,
} from "../../store/actions/AdminAction";
import { Row, Col, Form } from "react-bootstrap";

const Profile = (props) => {
  const [image, setImage] = useState({
    profile: {
      file: "",
      preview_image: "",
    },
  });

  useEffect(() => {
    if (props.profile.loading) {
      props.dispatch(fetchAdminDetailsStart());
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(updateAdminDetailsStart());
  };

  const handleProfileImageChange = (event) => {
    let reader = new FileReader();
    let file = event.currentTarget.files[0];
    reader.onloadend = () => {
      setImage({
        ...image,
        profile: {
          file: file,
          preview_image: reader.result,
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleProfileImageRemove = () => {
    setImage({
      ...image,
      profile: {
        file: "",
        preview_image: "",
      },
    });
  };

  return (
    <>
      <main class="h-full pb-16 overflow-y-auto content-wrapper">
        <div class="container px-6 mx-auto grid content-section">
          <div class="apps-card">
            <div class="px-4 py-3 mb-8 app-card mt-4 w-100">
              <h2 class="my-6 text-2xl font-semibold ">Profile</h2>
              <Form onSubmit={(event) => handleSubmit(event)}>
                {props.profile.loading ? (
                  "Loading.."
                ) : (
                  <>
                    <Row>
                      <Col lg={6}>
                        <div className="custom-input">
                          <label class="block">
                            <span>Name</span>
                            <input
                              class="block form-input"
                              placeholder="Enter Name"
                              name="name"
                              defaultValue={props.profile.data.admin.name}
                              onChange={(event) => {
                                props.dispatch(
                                  editAdminDetails(
                                    event.currentTarget.name,
                                    event.currentTarget.value
                                  )
                                );
                              }}
                            />
                          </label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="custom-input">
                          <label class="block">
                            <span>Email</span>
                            <input
                              class="block form-input"
                              placeholder="Enter Email"
                              name="email"
                              defaultValue={props.profile.data.admin.email}
                              onChange={(event) => {
                                props.dispatch(
                                  editAdminDetails(
                                    event.currentTarget.name,
                                    event.currentTarget.value
                                  )
                                );
                              }}
                            />
                          </label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={5}>
                            <div className="block mb-2">
                              <label class="block  mb-1">
                                <span>Favicon</span>
                              </label>
                              <div className="custom-file-btn-wrapper ">
                                <input
                                  id="fileSelect"
                                  type="file"
                                  accept="image/*"
                                  name="picture"
                                  onChange={(event) => {
                                    handleProfileImageChange(event);
                                    props.dispatch(
                                      editAdminDetails(
                                        event.currentTarget.name,
                                        event.currentTarget.files[0]
                                      )
                                    );
                                  }}
                                />
                                <div className="app-card-buttons">
                                  <button className="content-button status-button">
                                    Choose Profile Image
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={7}>
                            {image.profile.preview_image != "" ? (
                              <div className="preview-image-wrapper">
                                <img
                                  src={image.profile.preview_image}
                                  alt=""
                                  className="settings-previewimage "
                                />
                                <div className="file-remove-btn">
                                  <button
                                    className=" flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-full active:bg-red-600 hover:bg-red-600 focus:outline-none focus:shadow-outline-purple"
                                    aria-label="Delete"
                                    onClick={handleProfileImageRemove}
                                  >
                                    <svg
                                      fill="#fff"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      width="24"
                                    >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              props.profile.data.admin.picture != "" && (
                                <div>
                                  <img
                                    src={props.profile.data.admin.picture}
                                    alt=""
                                    className="settings-previewimage "
                                  />
                                </div>
                              )
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12}>
                        <div className="custom-input">
                          <label class="block col-span-2">
                            <span>Description</span>
                            <textarea
                              class="block"
                              rows="3"
                              placeholder="Enter some long form content."
                              name="about"
                              onChange={(event) => {
                                props.dispatch(
                                  editAdminDetails(
                                    event.currentTarget.name,
                                    event.currentTarget.value
                                  )
                                );
                              }}
                            >
                              {props.profile.data.admin.about}
                            </textarea>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="app-card-buttons sub-btn">
                      <button
                        className="content-button status-button"
                        onClick={handleSubmit}
                        disabled={props.profileInputData.buttonDisable}
                      >
                        {props.profileInputData.loadingButtonContent !== null
                          ? props.profileInputData.loadingButtonContent
                          : "Submit"}
                      </button>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

// export default Profile;

const mapStateToPros = (state) => ({
  profile: state.admins.profile,
  profileInputData: state.admins.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Profile);

import { combineReducers } from "redux";
import AdminReducer from "./AdminReducer";
import ErrorReducer from "./ErrorReducer";
import NFTReducer from "./NFTReducer";
import NotificationReducer from "./NotificationReducer";
import notifyReducer from "react-redux-notify";

export default combineReducers({
  errorDetails: ErrorReducer,
  notification: NotificationReducer,
  notifications: notifyReducer,
  nft: NFTReducer,
  admins: AdminReducer,
});

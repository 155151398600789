import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import configuration from "react-global-configuration";
import Aside from "./Aside";

const Sidebar = (props) => {
  return (
    <>
      {props.sideBarState.mobile ? (
        <>
          {props.sideBarState.mobileSideBar && (
            <>
              <div class="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"></div>
              <Aside sideBarState={props.sideBarState}/>
            </>
          )}
        </>
      ) : (
        <>
          <Aside sideBarState={props.sideBarState}/>
        </>
      )}
    </>
  );
};

export default Sidebar;

import React, { useState } from "react";
import NftLoot from "../../abis/KeyToMetaverseBSC.json";
import {
  Nav,
  Container,
  Navbar,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import Chain from "./images/chain.svg";
import "./GenerateNftIndex.css";
import { Link } from "react-router-dom";
import Web3 from "web3";
import Avatars, { createAvatar } from '@dicebear/avatars';
import sprites from '@dicebear/avatars-avataaars-sprites';
import { apiConstants } from "../Constant/constants";
import { useHistory } from "react-router";
import configuration from "react-global-configuration";


//Declare IPFS
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // leaving out the arguments will default to these values

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");


const GenerateNFTIndex = () => {

  const history = useHistory();

  const [blockchainLoading, setBlockchainLoading] = useState(true);
  const [account, setAccount] = useState("");
  const [nftLoot, setNftLoot] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [img, setImg] = useState("");
  const [nftWords, setNftWords] = useState([]);
  const [buffer, setBuffer] = useState("");
  const [buttonContent, setButtonContent] = useState(null);
  const [inputData, setInputData] = useState({
    title: "",
    description: ""
  })


  const handleConnectWallet = async (event) => {
    event.preventDefault();
    setButtonContent("Loading...")
    loadWeb3();
  }

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      loadBlockchainData();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      loadBlockchainData();
      return true;
    } else {
      setButtonContent(null);
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      return false;
    }
  }

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    // Load account
    const accounts = await web3.eth.getAccounts();

    setAccount(accounts[0]);
    // Network ID
    const networkId = await web3.eth.net.getId();
    const networkData = NftLoot.networks[networkId];
    if (networkData) {
      const nftLoot = new web3.eth.Contract(NftLoot.abi, configuration.get("configData.contract_address"));
      setNftLoot(nftLoot);

      const totalSupply = await nftLoot.methods.totalSupply().call();
      setTotalSupply(totalSupply);
      console.log("total Supply", totalSupply);

      // Load words.
      setBlockchainLoading(false);
      setButtonContent(null)
    } else {
      window.alert("Our NFT contract is deployed in Polygon. Please change the metamask network into Polygon and refresh the page.");
      setButtonContent(null)
    }
  }

  // Generate random number 
  const generateNumber = () => {
    return Math.floor(Math.random() * (apiConstants.max - apiConstants.min + 1) + apiConstants.min)
  }

  const convertDataURIToBinaryFF = (dataURI) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(
      Array.prototype.map.call(raw, function (x) {
        return x.charCodeAt(0);
      })
    );
  };


  const uploadNft = async (event) => {
    event.preventDefault();

    setButtonContent("Initiated...");
    if (inputData.title != "" && inputData.description != "") {
      let tempChest = apiConstants.chest[generateNumber()];
      let tempHead = apiConstants.head[generateNumber()];
      let tempHand = apiConstants.hand[generateNumber()];
      let tempFoot = apiConstants.foot[generateNumber()];
      let allWords = tempChest + tempFoot + tempHand + tempHead;

      const options = {
        base64: true
      };
      let avatars = new Avatars(sprites, options);
      let svgs = await avatars.create(allWords);

      try {
        setLoading(true)

        let imageData = await convertDataURIToBinaryFF(
          svgs
        );

        imageData = Buffer(imageData);

        //adding file to the IPFS

        ipfs.add(imageData, async (error, result) => {
          console.log("Ipfs result", result);
          if (error) {
            console.error(error);
            return;
          }
          setImg(result[0].hash);

          const json = generateJson({ name: inputData.title, description: inputData.description, imageHash: result[0].hash, chest: tempChest, head: tempHead, hand: tempHand, foot: tempFoot, allWords: allWords }).then(async (val) => {
            const cid = await ipfsJson.add(val);

            const tokenURIHash = await ipfsJson.cat(cid);

            // nftLoot.methods
            //  .safeMint(account, "https://ipfs.infura.io/ipfs/" + cid, allWords)
            //  .send({ from: account, value: "1000000000000000" })
            //  .once("receipt", (receipt) => {
            //   setLoading(false)
            //   setButtonContent(null);
            //   setImages((prevState) => ([...prevState, result[0].hash]))
            //   setNftWords((prevState) => ([...prevState, allWords]));
            //   history.push("/");
            //  });

            nftLoot.methods
              .ownerMint("https://ipfs.infura.io/ipfs/" + cid, allWords)
              .send({ from: account })
              .once("receipt", (receipt) => {
                setLoading(false)
                setButtonContent(null);
                setImages((prevState) => ([...prevState, result[0].hash]))
                setNftWords((prevState) => ([...prevState, allWords]));
                history.push("/");
              });
          });

        });

      } catch (error) {
        setButtonContent(null)
        console.log(error);
      }
    }
    else {
      console.log("error");
      setButtonContent(null)
    }
  };


  // generate random words. 


  // Generate metadata json file. 

  const generateJson = async (data) => {
    const metadata = JSON.stringify({
      description: data.description,
      external_url: "https://keystometaverse.com",
      image: "https://ipfs.infura.io/ipfs/" + data.imageHash,
      name: "Gaming Avatar " + data.name,
      text: data.allWords,
      attributes: [
        {
          "trait_type": "Chest",
          "value": data.chest
        },
        {
          "trait_type": "Foot",
          "value": data.foot
        },
        {
          "trait_type": "Hand",
          "value": data.hand
        },
        {
          "trait_type": "Head",
          "value": data.head
        },
      ]
    })
    console.log("Json", metadata);
    return metadata;
  }





  return (
    <>
      <div className="home-page">
        <Navbar expand="lg">
          <Container>
            <Link to="/">
              <Navbar.Brand >Home</Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#home">FAQ</Nav.Link>
                <Nav.Link href="#link">Resources</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="nft-page">
          <Container>
            <div className="nft-page-form">
              <h2>Create Your Own NFT</h2>

              <Row className="align-items-center">
                <Col md={6}>
                  <div className="right-image">
                    <img src={img != "" ? `https://ipfs.infura.io/ipfs/${img}` :
                      Chain
                    } />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="left-content text-center">
                    <Form>
                      <Form.Group className="mb-6">
                        <Form.Control
                          type="text"
                          placeholder="Title"
                          className="form-nft"
                          name="title"
                          value={inputData.title}
                          onChange={(event) => { setInputData({ ...inputData, title: event.target.value }) }}
                        />
                      </Form.Group>

                      <Form.Group className="mb-6 ">
                        <Form.Control
                          as="textarea"
                          placeholder="Description"
                          className="form-nft"
                          name="description"
                          value={inputData.description}
                          onChange={(event) => { setInputData({ ...inputData, description: event.target.value }) }}
                          rows={6}
                        />
                      </Form.Group>
                      {blockchainLoading ?
                        <Button type="submit" className="nft-btn" onClick={handleConnectWallet} disabled={buttonContent != null ? true : false}>
                          {buttonContent != null ? buttonContent : "Connect Wallet"}
                        </Button>
                        : <Button type="submit" className="nft-btn" onClick={uploadNft} disabled={buttonContent != null ? true : false}>
                          {buttonContent != null ? buttonContent : "Mint your NFT..!!"}
                        </Button>}
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default GenerateNFTIndex;

import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  ADMIN_CHANGE_PASSWORD_START,
  ADMIN_LOGIN_START,
  FETCH_ADMIN_DETAILS_START,
  UPDATE_ADMIN_DETAILS_START,
  SETTINGS_UPDATE_START,
  FETCH_SETTINGS_START
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/NotificationMessage";

import {
  adminLoginSuccess,
  adminLoginFailure,
  changePasswordStart,
  updateAdminDetailsFailure,
  updateAdminDetailsSuccess,
  fetchAdminDetailsFailure,
  fetchAdminDetailsSuccess,
  settingsUpdateSuccess,
  settingsUpdateFailure,
  fetchSettingsSuccess,
  fetchSettingsFailure,
  fetchSettingsStart
} from "../actions/AdminAction";

function* adminLoginAPI(action) {
  try {
    const response = yield api.postMethod("admin-login", action.data);
    if (response.data.success) {
      yield put(adminLoginSuccess(response.data.data));
      localStorage.setItem("userId", response.data.data.id);
      localStorage.setItem("accessToken", response.data.data.password);
      localStorage.setItem("picture", response.data.data.picture);
      window.location.assign("/list_of_nft");
    } else {
      yield put(adminLoginFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(adminLoginFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getAdminDetailsAPI() {
  try {
    const response = yield api.postMethod("admin-profile");
    if (response.data.success) {
      yield put(fetchAdminDetailsSuccess(response.data));
      localStorage.setItem("admin_picture", response.data.data.picture);
      localStorage.setItem("name", response.data.data.name);
    } else {
      yield put(fetchAdminDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchAdminDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* updateAdminDetailsAPI() {
  try {
    const inputData = yield select(
      (state) => state.admins.profileInputData.data
    );
    console.log(inputData)
    const response = yield api.postMethod("admin-update_profile", inputData);
    if (response.data.success) {
      yield put(updateAdminDetailsSuccess(response.data));
      localStorage.setItem("picture", response.data.data.picture);
      localStorage.setItem("name", response.data.data.name);

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/profile");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(updateAdminDetailsFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateAdminDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* adminChangePassword(action) {
  try {
    const response = yield api.postMethod("admin-change_password", action.data);
    if (response.data.success) {
      yield put(adminLoginSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.reload()
    } else {
      yield put(adminLoginFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(adminLoginFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* settingsUpdateAPI(action) {
  try {
    const response = yield api.postMethod("update_settings", action.data);
    if (response.data.success) {
      yield put(settingsUpdateSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/settings");
    } else {
      yield put(settingsUpdateFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(settingsUpdateFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSettingsAPI(action) {
  try {
    const response = yield api.postMethod("get_settings_json", action.data);
    yield put(fetchSettingsSuccess(response.data.data));
    // if (response.data.success) {
    //   yield put(fetchSettingsSuccess(response.data.data));
    // } else {
    //   yield put(fetchSettingsFailure(response.data.error));
    //   const notificationMessage = getErrorNotificationMessage(
    //     response.data.error
    //   );
    //   yield put(createNotification(notificationMessage));
    // }
  } catch (error) {
    yield put(fetchSettingsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(ADMIN_LOGIN_START, adminLoginAPI)]);
  yield all([
    yield takeLatest(UPDATE_ADMIN_DETAILS_START, updateAdminDetailsAPI),
  ]);
  yield all([yield takeLatest(FETCH_ADMIN_DETAILS_START, getAdminDetailsAPI)]);
  yield all([yield takeLatest(ADMIN_CHANGE_PASSWORD_START, adminChangePassword)]);
  yield all([yield takeLatest(SETTINGS_UPDATE_START, settingsUpdateAPI)]);
  yield all([yield takeLatest(FETCH_SETTINGS_START, fetchSettingsAPI)]);
}

// Notification on or off
export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Notification constant
export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

// User Authentication

export const ADMIN_LOGIN_START = "ADMIN_LOGIN_START";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";

// NFT

export const FETCH_LIST_OF_NFT_START = "FETCH_LIST_OF_NFT_START";
export const FETCH_LIST_OF_NFT_SUCCESS = "FETCH_LIST_OF_NFT_SUCCESS";
export const FETCH_LIST_OF_NFT_FAILURE = "FETCH_LIST_OF_NFT_FAILURE";

export const FETCH_SINGLE_NFT_START = "FETCH_SINGLE_NFT_START";
export const FETCH_SINGLE_NFT_SUCCESS = "FETCH_SINGLE_NFT_SUCCESS";
export const FETCH_SINGLE_NFT_FAILURE = "FETCH_SINGLE_NFT_FAILURE";

export const FETCH_SINGLE_NFT_PROPERTIES_START =
  "FETCH_SINGLE_NFT_PROPERTIES_START";
export const FETCH_SINGLE_NFT_PROPERTIES_SUCCESS =
  "FETCH_SINGLE_NFT_PROPERTIES_SUCCESS";
export const FETCH_SINGLE_NFT_PROPERTIES_FAILURE =
  "FETCH_SINGLE_NFT_PROPERTIES_FAILURE";

export const FETCH_LIST_NFT_PROPERTIES_START =
  "FETCH_LIST_NFT_PROPERTIES_START";
export const FETCH_LIST_NFT_PROPERTIES_SUCCESS =
  "FETCH_LIST_NFT_PROPERTIES_SUCCESS";
export const FETCH_LIST_NFT_PROPERTIES_FAILURE =
  "FETCH_LIST_NFT_PROPERTIES_FAILURE";

export const DELETE_SINGLE_NFT_START = "DELETE_SINGLE_NFT_START";
export const DELETE_SINGLE_NFT_SUCCESS = "DELETE_SINGLE_NFT_SUCCESS";
export const DELETE_SINGLE_NFT_FAILURE = "DELETE_SINGLE_NFT_FAILURE";

export const UPLOAD_NFT_FILES_START = "UPLOAD_NFT_FILES_START";
export const UPLOAD_NFT_FILES_SUCCESS = "UPLOAD_NFT_FILES_SUCCESS";
export const UPLOAD_NFT_FILES_FAILURE = "UPLOAD_NFT_FILES_FAILURE";

export const FETCH_ADMIN_DETAILS_START = "FETCH_ADMIN_DETAILS_START";
export const FETCH_ADMIN_DETAILS_SUCCESS = "FETCH_ADMIN_DETAILS_SUCCESS";
export const FETCH_ADMIN_DETAILS_FAILURE = "FETCH_ADMIN_DETAILS_FAILURE";

export const EDIT_ADMIN_DETAILS = "EDIT_ADMIN_DETAILS";

export const UPDATE_ADMIN_DETAILS_START = "UPDATE_ADMIN_DETAILS_START";
export const UPDATE_ADMIN_DETAILS_SUCCESS = "UPDATE_ADMIN_DETAILS_SUCCESS";
export const UPDATE_ADMIN_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_ADMIN_CHANGE_PASSWORD = "EDIT_ADMIN_CHANGE_PASSWORD";

export const ADMIN_CHANGE_PASSWORD_START = "ADMIN_CHANGE_PASSWORD_START";
export const ADMIN_CHANGE_PASSWORD_SUCCESS = "ADMIN_CHANGE_PASSWORD_SUCCESS";
export const ADMIN_CHANGE_PASSWORD_FAILURE = "ADMIN_CHANGE_PASSWORD_FAILURE";

export const SEARCH_NFT_IMAGE_START = "SEARCH_NFT_IMAGE_START";
export const SEARCH_NFT_IMAGE_SUCCESS = "SEARCH_NFT_IMAGE_SUCCESS";
export const SEARCH_NFT_IMAGE_FAILURE = "SEARCH_NFT_IMAGE_FAILURE";

export const PAGINATE_LIST_OF_NFT_START = "PAGINATE_LIST_OF_NFT_START";

export const SETTINGS_UPDATE_START = "SETTINGS_UPDATE_START";
export const SETTINGS_UPDATE_SUCCESS = "SETTINGS_UPDATE_SUCCESS";
export const SETTINGS_UPDATE_FAILURE = "SETTINGS_UPDATE_FAILURE";

export const FETCH_SETTINGS_START = "FETCH_SETTINGS_START";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";

export const SORT_LIST_OF_NFT_START = "SORT_LIST_OF_NFT_START";
export const SORT_LIST_OF_NFT_SUCCESS = "SORT_LIST_OF_NFT_SUCCESS";
export const SORT_LIST_OF_NFT_FAILURE = "SORT_LIST_OF_NFT_FAILURE";

export const EDIT_NFT_DETAILS = "EDIT_NFT_DETAILS"

export const UPDATE_NFT_DETAILS_START = "UPDATE_NFT_DETAILS_START";
export const UPDATE_NFT_DETAILS_SUCCESS = "UPDATE_NFT_DETAILS_SUCCESS";
export const UPDATE_NFT_DETAILS_FAILURE = "UPDATE_NFT_DETAILS_FAILURE";

export const ADD_NFT_IMAGE_START = "ADD_NFT_IMAGE_START";
export const ADD_NFT_IMAGE_SUCCESS = "ADD_NFT_IMAGE_SUCCESS";
export const ADD_NFT_IMAGE_FAILURE = "ADD_NFT_IMAGE_FAILURE";


import React from "react";
import { Modal, Button } from "react-bootstrap";

const MintNowModal = (props) => {

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mint Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <label className="block text-sm">
              <span className="text-gray-700 dark:text-gray-400">
                Wallet Address
              </span>
              <input
                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                placeholder="Enter Your Wallet Address"
                name="wallet_address"
                value={props.minterWalletAddress}
                onChange={(event) => props.setMinterWalletAdddress(event.target.value)}
              />
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button disabled={props.mintButtonContent != "" ? true : false} onClick={event => props.mintYourNFT(event)} className="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple" variant="primary">
            {props.mintButtonContent != "" ? props.mintButtonContent : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MintNowModal;

import { all, fork } from "redux-saga/effects";
import ErrorSaga from "./ErrorSaga";
import NotificationSaga from "./NotificationSaga";
import NFTSaga from "./NFTSaga";
import AdminSaga from "./AdminSaga";

export default function* rootSaga() {
  yield all([fork(ErrorSaga)]);
  yield all([fork(NotificationSaga)]);
  yield all([fork(NFTSaga)]);
  yield all([fork(AdminSaga)]);
}

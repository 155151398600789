import React, { useState } from "react";
import {
  Nav,
  Container,
  Navbar,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { uploadNFTFilesStart } from "../store/actions/NFTActions";

const NFTImageUpload = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState({
    xlSheet: null,
    zipFile: null,
  });

  const handleUploadXLSheet = (event) => {
    setUploadedFiles({
      ...uploadedFiles,
      xlSheet: {
        name: event.target.files[0].name,
        file: event.target.files[0],
      },
    });
  };

  const handleUploadZipFile = (event) => {
    setUploadedFiles({
      ...uploadedFiles,
      zipFile: {
        name: event.target.files[0].name,
        file: event.target.files[0],
      },
    });
  };

  const handleFileUpload = () => {
    if (uploadedFiles.xlSheet != null && uploadedFiles.zipFile != null) {
      const newData = {
        import_excel_file: uploadedFiles.xlSheet.file,
        import_zip_file: uploadedFiles.zipFile.file,
      };
      console.log(newData);
      props.dispatch(uploadNFTFilesStart(newData));
    }
  };

  return (
    <>
      <main class="h-full pb-16 overflow-y-auto content-wrapper">
        <div class="container px-6 mx-auto grid content-section">
          <div className="apps-card">
            <div class="px-4 py-3 mb-8 app-card mt-4 w-100">
              <h2 class="my-6 text-2xl font-semibold ">
                Upload Files for NFT Image
              </h2>
              <Container>
                <Row className="align-items-center mb-4">
                  <Col xs={12}>
                    <p className="align-items-center">
                      NOTE: Please download the sample CSV and zip. You need to
                      upload CSV and ZIP file as in the reference. Very
                      Important - zip folder name should be Mint
                    </p>
                    <br />
                    <div className="nft-upload-files-form mb-3">
                      <div className="app-card-buttons">
                        <a
                          href={
                            window.location.origin +
                            "/assets/sample/sample-excel.xlsx"
                          }
                          download
                          className="content-button status-button"
                        >
                          Download Sample CSV
                        </a>
                      </div>

                      <div className="app-card-buttons">
                        <a
                          href={
                            window.location.origin +
                            "/assets/sample/images-zip.zip"
                          }
                          download
                          className="content-button status-button"
                        >
                          Download Sample Zip
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className=" text-center">
                      <Form className="nft-upload-files-form">
                        <Form.Group className="">
                          {uploadedFiles.xlSheet != null && (
                            <p className="mb-3">{uploadedFiles.xlSheet.name}</p>
                          )}
                          <div className="custom-file-btn-wrapper">
                            <input
                              id="fileSelect"
                              type="file"
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              onChange={(event) => handleUploadXLSheet(event)}
                            />
                            <div className="app-card-buttons">
                              <button className="content-button status-button">
                                Choose CSV File
                              </button>
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group className="">
                          {uploadedFiles.zipFile != null && (
                            <p className="mb-3">{uploadedFiles.zipFile.name}</p>
                          )}
                          <div className="custom-file-btn-wrapper">
                            <input
                              id="fileSelect"
                              type="file"
                              accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                              onChange={(event) => handleUploadZipFile(event)}
                            />
                            <div className="app-card-buttons">
                              <button className=" content-button status-button">
                                Chosse Zip File
                              </button>
                            </div>
                          </div>
                        </Form.Group>
                      </Form>
                      <div className="w-100">
                        <Button
                          type="button"
                          size="sm"
                          onClick={handleFileUpload}
                          className="nft-btn px-4 py-2"
                          disabled={
                            uploadedFiles.xlSheet == null
                              ? true
                              : uploadedFiles.zipFile == null
                              ? true
                              : false || props.uploadNFTFiles.buttonDisabled
                          }
                        >
                          {props.uploadNFTFiles.loadingButttonContent == null
                            ? "Upload Files"
                            : props.uploadNFTFiles.loadingButttonContent}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

const mapStateToPros = (state) => ({
  uploadNFTFiles: state.nft.uploadNFTFiles,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NFTImageUpload);
